import React from 'react';

import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Button } from 'workmotion-design-system';

import { ButtonProps } from 'shared-components/filters-menu/menu';

const FiltersButton: React.FC<ButtonProps> = ({ onClick }) => (
    <Button
        btnType="subtle"
        data-testid="filters-menu-button"
        onClick={onClick}
        style={{
            width: '2.5rem',
            height: '2.5rem',
            padding: '0.688rem',
            marginLeft: '1rem',
        }}
    >
        <FilterAltRoundedIcon style={{ width: '1rem', height: '1rem' }} />
    </Button>
);

export { FiltersButton };
