import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { StyledTableHeadCell } from './table';

interface TableRowProps {
    readonly isSelected?: boolean;
}

const border = `1px solid ${palette.greyscale.antiFlashWhite}`;

const Table = styled.table({
    width: '100%',
    '& tr:last-child td:first-child': {
        borderBottomLeftRadius: 8,
    },
    '& tr:last-child td:last-child': {
        borderBottomRightRadius: 8,
    },
    '& th:first-child': {
        borderTopLeftRadius: 8,
    },
    '& th:last-child': {
        borderTopRightRadius: 8,
    },
    '& td:first-child': {
        borderLeft: border,
    },
    '& td:last-child': {
        borderRight: border,
    },
    borderCollapse: 'separate',
    borderSpacing: 0,
});

const TableHeadRow = styled.tr({
    background: palette.greyscale.antiFlashWhite,
    height: 46,
    '& > :first-child': {
        paddingLeft: '1.5rem',
    },
    '& > :last-child': {
        paddingRight: '1.5rem',
    },
});

const HeaderCell = styled(StyledTableHeadCell)({
    fontSize: 12,
    maxWidth: 150,
    lineHeight: 0.8,
    color: palette.greyscale.UCLABlue,
    fontFamily: 'Work Sans',
    fontWeight: 600,
    textAlign: 'left',
    padding: '0 15px',
});

const TableCell = styled.td({
    maxWidth: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
    verticalAlign: 'middle',
    fontFamily: 'Work Sans',
    color: palette.greyscale.persianBlue,
    fontWeight: 400,
    borderBottom: border,
    padding: '0 15px',
    '&[data-is-smaller-width="true"]': {
        '@media(max-width: 1350px)': {
            maxWidth: '6.25rem',
        },
    },
});

const TableRow = styled.tr((props: TableRowProps) => ({
    height: 64,
    background: props.isSelected
        ? palette.tertiary.aquaHaze
        : `${palette.secondary.white}`,
    '&[data-is-clickable]': {
        transition: 'background-color 450ms ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: palette.tertiary.aquaHaze,
        },
    },
    '& > :first-child': {
        paddingLeft: '1.5rem',
    },
    '& > :last-child': {
        paddingRight: '1.5rem',
    },
}));

export const ActionItemsCellContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});

export { HeaderCell, Table, TableRow, TableHeadRow, TableCell };
