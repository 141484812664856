import React from 'react';

import { Navigate, Route } from 'react-router-dom';
import { PageNotFound, Spinner } from 'workmotion-design-system';

import { useUserIdentity } from 'authentication/use-user-identity';
import { handleRequestError } from 'helpers/handle-request-error';
import { en } from 'localization';
import { conditionalRoute } from 'shared-components/conditional-route';
import { SentryRoutes } from 'tracking/routes';
import { RequestError } from 'types';

import { EmployeeTimesheetView } from './time-sheet/employee-view';
import { ManageTimesheetView } from './time-sheet/manage-view';
import { EmployeeTimeTrackingView } from './time-tracking/employee-view';
import ExportRecords from './time-tracking/employee-view/export-records';

export const AppRoutes: React.FC = () => {
    const { isLoading, data: identity, isError, error } = useUserIdentity();

    const {
        generic: { genricError },
    } = en;

    if (isLoading)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spinner data-testid="app-loading" />
            </div>
        );

    if (isError) {
        handleRequestError(error as RequestError, genricError);
    }

    if (!identity?.isAuthenticated) {
        return (
            <SentryRoutes>
                <Route path="*" element={<Navigate to="/" replace />}></Route>
            </SentryRoutes>
        );
    }

    if (identity?.isEmployee) {
        return (
            <SentryRoutes>
                <Route
                    path="/timesheet"
                    element={<EmployeeTimesheetView />}
                ></Route>

                <Route
                    path="/time-tracking"
                    element={<EmployeeTimeTrackingView />}
                ></Route>

                <Route path="*" element={<PageNotFound />}></Route>
            </SentryRoutes>
        );
    }

    return (
        <SentryRoutes>
            <Route path="/timesheet" element={<ManageTimesheetView />}></Route>

            <Route
                path="/time-tracking/export/:userId/:fileKey"
                element={conditionalRoute(
                    identity.groups.includes('ExportLTTRecords'),
                    <ExportRecords />
                )}
            ></Route>

            <Route
                path="/time-tracking"
                element={<EmployeeTimeTrackingView />}
            ></Route>

            <Route path="*" element={<PageNotFound />}></Route>
        </SentryRoutes>
    );
};
